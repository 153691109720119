<template>
    <div class="monitor">
        <div id="backgroundMap"></div>
        <right></right>
    </div>
</template>

<script>
    import Right from "./right.vue";
    export default {
        mounted() {
            const map = new BMap.Map("backgroundMap");
            const point = new BMap.Point(116.404, 39.915);
            map.centerAndZoom(point, 15);
            map.enableScrollWheelZoom(true);
        },
        components: {
            Right
        }
    };
</script>
<style lang="scss" scoped>
    .monitor {
        height: 100%;
        position: relative;
        #backgroundMap {
            height: 100%;
        }
    }
</style>
