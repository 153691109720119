<template>
    <div class="right">
        <el-radio-group v-model="status" size="mini" @change="orderChange">
            <el-radio-button label="0">待发车（{{ countInfo.wait }}）</el-radio-button>
            <el-radio-button label="1">运输中（{{ countInfo.inTransit }}）</el-radio-button>
            <el-radio-button label="2">已完成（{{ countInfo.finish }}）</el-radio-button>
        </el-radio-group>

        <div>
            <div v-for="w in wOrders" :key="w.wOrderId">
                <div>
                    <img src="@/assets/driver.svg" />
                    <div>
                        <div>车牌号：</div>
                        <div>司机：</div>
                        <div>调度单号：</div>
                    </div>
                </div>
                <div>
                    <div>调度发车时间：</div>
                    <div>产废企业：</div>
                    <div>调度单号：</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        methods: {
            orderChange() {
                this.getList();
            },
            getList() {
                this.$http
                    .post("/monitor/querySchedule", {
                        status: this.status
                    })
                    .then(res => {
                        console.log(res);
                    });
            }
        },
        mounted() {
            this.$http.post("/transport/track/completion").then(res => {
                // this.header.date = res.detail.date;
                // this.header.finish = res.detail.finish;
                // this.header.inTransit = res.detail.inTransit;
                // this.header.wait = res.detail.wait;
                this.countInfo = res.detail;
            });
            this.getList();
        },
        data() {
            return {
                status: "0",
                countInfo: {},

                wOrders: []
            };
        }
    };
</script>
<style lang="scss" scoped>
    .right {
        position: absolute;
        right: 20px;
        top: 20px;
    }
</style>
